<template>
  <div class="header-box">
    <div class="header-wrap">
      <div class="header">
        <div class="nav">
          <div class="nav-left">
            <img class="logo-img" :src="src1" alt="" />
            <ul>
              <li style="padding-left: 0;" @click="backTop">
                <router-link to="/home" class="active">Home</router-link>
              </li>
              <li @click="backTop">
                <router-link to="/nft">CTM</router-link>
              </li>
              <li @click="backTop">
                <router-link to="/cryptos">Wallet</router-link>
              </li>
              <li @click="backTop">
                <router-link to="/participate">NFT Solutions</router-link>
              </li>
              <!-- <li >
                <router-link to="/nftinquire">NFT lnquire</router-link>
              </li> -->
              <li @click="backTop">
                <router-link to="/nftmarket">NFT Market</router-link>
              </li>
            </ul>
          </div>
          <!-- <div  v-show="pathUrl" :style="{cursor: 'pointer', width: '150px', position: 'absolute', top: '40px', right: !pathUrl ? '50px' : (userInfo.token ? '340px' : '140px')}">
            <el-dropdown
              trigger="click"
              class="international"
              @command="handleSetLanguage"
            >
                <span
                  class="el-dropdown-link my_message"
                  style="color: #fff; font-size: 16px;"
                  >{{ $t('language.language') }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item style="height: 50px; line-height: 50px;"
                  :disabled="language === $t('language.zh')"
                  command="zh-CN"
                    >{{ $t('language.zhName') }}</el-dropdown-item>
                    <el-dropdown-item style="height: 50px; line-height: 50px;"
                    :disabled="language === $t('language.tw')"
                  command="tw"
                    >{{ $t('language.twName') }}</el-dropdown-item>
                    <el-dropdown-item style="height: 50px; line-height: 50px;"
                    :disabled="language === $t('language.en')"
                  command="en"
                    >{{ $t('language.enName') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </div> -->
          <!-- <div v-show="pathUrl" class="head_right_login" style="height: 105px;">
            <div
              v-if="userInfo.token"
              style="display: flex; align-items: center;width: 300px;"
            >
              <img
                style="border-radius: 50%;margin-right: 10px;"
                :src="userInfo.imagePath ? userInfo.imagePath : headImg"
                alt=""
              />
              <el-dropdown trigger="click" style="cursor: pointer;">
                <span
                  class="el-dropdown-link my_message"
                  style="color: #fff; font-size: 20px;"
                  >{{ $t('headerBox.my') }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item style="height: 50px; line-height: 50px;"
                    ><router-link to="/nftmarket/marketPayList"
                      >{{ $t('headerBox.orderList') }}</router-link
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <p style="margin-right: 50px;"><router-link style="color:#fff;" to="/nftmarket/marketPayList"
                      >{{ $t('headerBox.my') }}</router-link
                    ></p>
              <p @click="dialogVisible = true">Log out</p>
            </div>
            <div
              v-else
              style="color: #fff; cursor: pointer; font-size: 16px;"
              @click="setLoginShow(true)"
            >
              Login
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <el-dialog
  :title="$t('headerBox.outLogin')"
  :visible.sync="dialogVisible"
  top="15%"
  width="30%">
  <span>{{ $t('headerBox.outLoginOk') }}</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">{{ $t('headerBox.cancel') }}</el-button>
    <el-button type="primary" @click="okBtn">{{ $t('headerBox.Ok') }}</el-button>
  </span>
</el-dialog> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// import { headImg } from '@/common/common.js'
export default {
  name: 'Header-box',
  data() {
    return {
      src1: '/img/Hunter LOGO FOR black BG Copy@2x.png',
      dialogVisible: false,
      // headImg: localStorage.getItem('headImg'),
      // pathUrl: this.$route.path != '/home' && this.$route.path != '/nft' && this.$route.path != '/cryptos' && this.$route.path != '/participate',
    }
  },
  computed: {
    ...mapState(['userInfo', 'language']),
  },
  created() {
    // if (!localStorage.getItem('headImg')) {
    //   this.getHeadImg()
    // }
  },
  methods: {
    ...mapMutations(['setLoginShow', 'remTokenInfo']),
    // 讓用戶隨機使用默認頭像
    // getHeadImg() {
    //   if (!localStorage.getItem('headImg')) {
    //     let a = Math.floor(Math.random() * 10)
    //     let img = headImg[a].imgUrl
    //     localStorage.setItem('headImg', img)
    //     this.headImg = img
    //   }
    // },
    // 把选择的语言传入vuex
    // handleSetLanguage(lang) {
    //   this.$i18n.locale = lang
    //   this.$store.commit('setLanguage', lang)
    // },
    // okBtn() {
    //   this.remTokenInfo()
    //   this.dialogVisible = false
    //   this.$message.success(this.$t('message.escLoginOk'))
    //   this.$router.push('/nftmarket')
    // },
    backTop() {
      window.scrollTo(99999, 0)
    },
  },
}
</script>

<style lang="less" scoped>
// .head_right_login {
//     display: flex;
//     align-items: center;
//     position: absolute;
//     top: 0;
//     right: 59px;
//     z-index: 99999;
//     img {
//       width: 58px;
//       height: 58px;
//       cursor: pointer;
//     }
//     p {
//       font-size: 20px;
//       color: #fff;
//       cursor: pointer;
//     }
//     .my_message {
//       margin-left: 16px;
//       margin-right: 26px;
//     }
//   }
//   .el-dropdown-menu__item {
//     line-height: 50px !important;
//   }
</style>
