<template>
  <div class="wallet-content head-height">
    <!-- <div class="bx"> -->
        <div class="wallet-content-box">
      <div class="wallet-content-top">
          <img class="phone-img" src="/img/loginctm@2x.png" alt="" />
        <div class="download-box">
          <img class="top-logo-img" src="/img/Group 31 Copy@2x.png" alt="" />
          <div class="download-box-title">
            Buy, Store, Send and Exchange tokens
          </div>
          <div class="download-box-text">
            Hunter Wallet is an easy-to-use, secure and versatile wallet for
            users to buy NFTs<br />directly with cash.
          </div>
          <img class="download-box-logo" src="/img/Group 44@2x.png" alt="" />
          <div class="download-box-logo-text">Download</div>
          <div class="download-option">
            <a
              href="https://apps.apple.com/cn/app/hunter-king-wallet/id1491881321"
            ></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.hunter.wallet"
            ></a>
            <a href="/android/latest_release.apk"></a>
          </div>
        </div>
        </div>
      </div>
    <!-- </div> -->
    <div class="wallet-content-bottom">
      <img class="bottom-bg-img" src="/img/Group 29@2x.png" alt="" />
      <img class="bottom-logo-img" src="/img/Group 33@2x.png" alt="" />
      <div class="bottom-title">NFT Gallery</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.wallet-content {
  // .bx {
  //   width: 6.74rem + 2.84;
  //   margin: 0 auto;
  .wallet-content-box {
    padding-left: 4.72rem;
    .wallet-content-top {
      position: relative;
      height: 6.05rem;
      box-sizing: border-box;
      padding-top: .96rem;
      .phone-img {
        width: 1.92rem;
        height: 3.82rem;
        margin-left: 1.5rem;
      }
      .download-box {
        position: absolute;
        top: .96rem;
        left: 4rem;
        .top-logo-img {
          position: absolute;
          top: .3rem;
          left: 0;
          width: 1.75rem;
          height: .71rem;
        }
        .download-box-title {
          position: absolute;
          top: 1.25rem;
          left: 0;
          width: 4.43rem;
          font-size: .24rem;
          font-weight: 500;
          color: #ffffff;
        }
        .download-box-text {
          position: absolute;
          top: 1.83rem;
          left: 0;
          width: 4.91rem;
          font-size: .12rem;
          font-weight: 500;
          color: #ffffff;
          line-height: .17rem;
        }
        .download-box-logo {
          width: 3.3rem;
          height: .28rem;
          position: absolute;
          top: 2.67rem;
          left: 0;
        }
        .download-box-logo-text {
          position: absolute;
          top: 2.78rem;
          left: .21rem;
          font-size: .12rem;
          color: #e0e0e0;
        }
        .download-option {
          position: absolute;
          top: 3.03rem;
          display: flex;
          a {
            display: inline-block;
            width: .9rem;
            height: .27rem;
            margin-right: .25rem;
          }
          a:nth-child(1) {
            background: url('/img/download_apple2@2x.png');
            background-size: 100%;
          }
          a:nth-child(2) {
            background: url('/img/download_google2@2x.png');
            background-size: 100%;
          }
          a:nth-child(3) {
            background: url('/img/download_apk2@2x.png');
            background-size: 100%;
          }
        }
      }
    }
}
  // }
  .wallet-content-bottom {
    position: relative;
    background-color: #181818;
    .bottom-bg-img {
      height: 6.4rem;
      width: 100%;
      margin: 0 auto;
    }
    .bottom-logo-img {
      position: absolute;
      top: 1.75rem;
      left: 50%;
      transform: translate(-50%, 0);
      width: 7.14rem;
      height: 3.63rem;
    }
    .bottom-title {
      position: absolute;
      top: .88rem;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: .44rem;
      font-weight: 300;
      color: #ffffff;
    }
    .contact-us-wallet {
      position: absolute;
      right: 3.52rem;
      bottom: 1.1rem;
    }
  }
}
</style>
