<template>
  <div class="CTM-content head-height">
    <!-- <div class="bx"> -->
      <div class="hunter-ctm">
        <img class="hunter-ctm-bg" src="/img/Group 9xx@2x.png" alt="" />
        <img class="hunter-ctm-logo" src="/img/Group 18 Copy 4@2x.png" alt="" />
        <img class="hunter-ctm-left" src="/img/Group 12@x2xx.png" alt="" />
        <img class="hunter-ctm-right" src="/img/Group 12 Copyx@2x.png" alt="" />
        <div class="hunter-ctm-title">
          Hunter CTM<br />
          The easiest and fastest way to trade NFTs
        </div>
        <div class="hunter-ctm-text">
          24-hour operation<br />
          30 seconds to complete the transaction
        </div>
      </div>
      <!-- </div> -->
      <div class="middle">
      <div class="middle-box">
        <img class="middle-right-top" src="/img/Group Coxpy@2x.png" alt="" />
        <img class="middle-left-bottom" src="/img/Groupxx@2x.png" alt="" />
        <img class="middle-left-top" src="/img/Group 3@x2x.png" alt="" />
        <img class="middle-right-bottom" src="/img/Group 2xx@2x.png" alt="" />
        <div class="middle-text1">
          Multi-currency support<br />
          Buy NFTs with only cash and mobile number
        </div>
        <div class="middle-text2">
          Cash to NFT and NFT to cash transactions<br />Multiple payment methods
        </div>
      </div>
    </div>
      <!-- <div class="placeholder"></div> -->
      <div class="ATM-Locations-box">
      <!-- <img
        class="ATM-Locations-logo"
        src="/img/Group 25 Copy 2@2x.png"
        alt=""
      /> -->
      <div class="CTM-footer">
        <img src="/img/Group 21@21xx.png" alt="" />
        <!-- <div class="ATM-Locations-swiper">
          <swiper :options="swiperOption" ref="mySwiper2">
            <swiper-slide
              v-for="(item, index) in ATMLocationsList"
              :key="index"
            >
              <div class="swiperContent">
                <div
                  class="swiperContent-list"
                  v-for="(value, index) in item.List"
                  :key="index"
                >
                  <div class="swiperContent-title">{{ value.title }}</div>
                  <div class="swiperContent-text1">{{ value.text1 }}</div>
                  <div class="swiperContent-text2">{{ value.text2 }}</div>
                  <div class="swiperContent-text3">{{ value.text3 }}</div>
                  <div class="swiperContent-text4">{{ value.text4 }}</div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div> -->
      </div>
      </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  components: {
    // swiper,
    // swiperSlide,
  },
  data() {
    return {
      
      swiperOption: {
        loop: false,
        autoplay: {
          delay: 3500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 800,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
.CTM-content {
  width: 100%;
  // .bx {
  //   width: 1233px;
  //   margin: 0 auto;
  //   overflow: hidden;
  // }
  .middle {
    // position: absolute;
    width: 100%;
    // top: 174px + 605px;
    // left: 0;
    // height: 679px + 200px;
    box-sizing: border-box;
    background-color: #151515;
    height: 8.57rem;
    margin-bottom: .71rem;
    .middle-box {
      position: relative;
      width: 12.33rem;
      margin: 0 auto;
      // margin-left: 420px;
      height: 100%;
      .middle-right-top {
        position: absolute;
        top: 1rem;
        left: 6.72rem;
        width: 4.68rem;
        height: 4.11rem;
      }
      .middle-left-bottom {
        position: absolute;
        top: 3.68rem;
        left: 1.9rem;
        width: 4.68rem;
        height: 4.11rem;
      }
      .middle-left-top {
        position: absolute;
        top: 1.85rem;
        left: 2.51rem;
        width: 1.03rem;
        height: 1.43rem;
      }
      .middle-right-bottom {
        position: absolute;
        top: 5.01rem;
        left: 6.22rem;
        width: 1.03rem;
        height: 1.43rem;
      }
      .middle-text1 {
        position: absolute;
        top: 2.35rem;
        left: 3.58rem;
        font-size: .16rem;
        font-weight: 400;
        color: #ffffff;
      }
      .middle-text2 {
        position: absolute;
        top: 5.43rem;
        left: 7.29rem;
        font-size: .16rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
    .hunter-ctm {
      position: relative;
      margin-left: 4.72rem;
      margin-bottom: .35rem;
      .hunter-ctm-bg {
        width: 6.74rem;
        height: 6.05rem;
      }
      .hunter-ctm-logo {
        position: absolute;
        top: 2rem;
        left: 4.64rem;
        width: .5rem;
        height: .85rem;
      }
      .hunter-ctm-left {
        position: absolute;
        top: 3.54rem;
        left: 4.64rem;
        width: .64rem;
        height: .64rem;
      }
      .hunter-ctm-right {
        position: absolute;
        top: 3.54rem;
        left: 5.25rem;
        width: .64rem;
        height: .64rem;
      }
      .hunter-ctm-title {
        position: absolute;
        top: 2.24rem;
        left: 5.36rem;
        font-size: .24rem;
        font-weight: 500;
        color: #ffffff;
      }
      .hunter-ctm-text {
        position: absolute;
        top: 3.02rem;
        left: 4.67rem;
        font-size: .12rem;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .ATM-Locations-box {
      height: 6.65rem;
    .ATM-Locations-logo {
      width: .99rem;
      height: .44rem;
      margin-left: 5.34rem;
      margin-bottom: .11rem;
    }
    .CTM-footer {
      position: relative;
      // top: 0;
      // left: 0;
      width: 14.45rem;
      height: 3.88rem;
      // padding-bottom: 1.51rem;
      padding-left: 4.14rem;
      // padding-right: 4.14rem;
      box-sizing: border-box;
      img {
        position: absolute;
        top: 0;
        left: 2.38rem;
        width: 14.45rem;
        height: 3.88rem;
      }
      .ATM-Locations-swiper {
        position: absolute;
        top: 0;
        // height: 214px;
        width: 100%;
        padding-top: .47rem;
        padding-right: 3rem;
        box-sizing: border-box;
        .swiper-container {
          height: 3.88rem;
          .swiperContent {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-left: 1.45rem;
            .swiperContent-list {
              padding-top: .33rem;
              color: #dedede;
              font-weight: normal;
              font-size: .12rem;
              margin-right: .33rem;
              width: 2.592rem;
              .swiperContent-title {
                font-size: .22rem !important;
                margin-bottom: .09rem;
              }
              .swiperContent-text1 {
                margin-bottom: .04rem;
              }
              .swiperContent-text2 {
                width: 2.1rem;
                margin-bottom: .11rem;
              }
              .swiperContent-text3 {
                margin-bottom: .07rem;
              }
            }
          }
        }
        .swiper-button-next {
          width: .68rem;
          height: .68rem;
          background: url('/img/Group 7@2x.png');
          background-size: 100%;
          right: 0;
        }
        .swiper-button-prev {
          width: .68rem;
          height: .68rem;
          background: url('/img/Group 7 Copy@2x.png');
          background-size: 100%;
          left: 0;
        }
        /deep/ .swiper-pagination-bullet {
            background: #fff !important;
          }
          /deep/ .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: 0;
          }
      }
    }
}
}
</style>
