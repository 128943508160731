<template>
  <div class="contact-us-content">
    <img @click="dialogVisible = !dialogVisible" class="contact-us" src="/img/contact us@2x.png" alt="" />
    <el-dialog
  :visible.sync="dialogVisible"
  :append-to-body="true"
  :show-close="false">
  <div class="relation">
      <img class="relation-img" src="/img/Group 202@2x.png" alt="" />
      <div class="contact-us-title">Contact Us</div>
      <div class="contact-us-text1">WhatsApp</div>
      <div class="contact-us-text2">WeChat</div>
      <div class="contact-us-text3">More information</div>
      <div class="contact-us-text4">Instagram<br />crypto.atm</div>
      <div class="contact-us-text5">Twritter<br />hunter_ctm</div>
    </div>
</el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    }
  }
}
</script>

<style lang="less" scoped>
.contact-us-content {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: .05rem;
  .contact-us {
    position: absolute;
    bottom: .5rem;
    right: .1rem;
    width: 1.28rem;
    height: .49rem;
    cursor: pointer;
  }
}
/deep/ .el-dialog {
  position: absolute;
    right: 1.25rem;
    bottom: .65rem;
  width: 3.11rem !important;
  height: 3.97rem;
  box-sizing: border-box;
  border-radius: .15rem;
}
.relation {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  font-size: .16rem;
  font-weight: 600;
  color: #a3a3a3;
  img {
    width: 3.11rem;
    height: 3.97rem;
  }
}
.contact-us-title {
  position: absolute;
  top: .22rem;
  left: .26rem;
}
.contact-us-text1 {
  position: absolute;
  top: 1rem;
  left: 1.11rem;
}
.contact-us-text2 {
  position: absolute;
  top: 1.78rem;
  left: 1.11rem;
}
.contact-us-text3 {
  position: absolute;
  top: 2.48rem;
  left: .26rem;
}
.contact-us-text4 {
  position: absolute;
  top: 2.79rem;
  left: .68rem;
}
.contact-us-text5 {
  position: absolute;
  top: 3.29rem;
  left: .68rem;
}
</style>
