<template>
  <div class="solutions-content head-height">
    <div class="solutions-content-top">
      <img class="top-bg-img" src="/img/Group 91@2x.png" alt="" />
      <img class="top-logo-img" src="/img/Group@221x.png" alt="" />
      <div class="top-title">NFT Solutions</div>
      <div class="top-text">
        Hunter is helping enterprises to relieve the obstacles of traditional
        operating methods.<br />
        Utilize NFT technology to provide unique experiences and products to the
        target<br />audience of enterprises in the dimension of blockchain and
        Metaspace.
      </div>
    </div>
    <div class="solutions-content-bottom">
      <img class="bottom-bg-img" src="/img/Group 239@2x.png" alt="" />
      <div class="bottom-options">
        <div
          class="options-bg"
          style="margin-right: 20px; margin-bottom: 23px;"
        >
          <img class="bg-logo" src="/img/Group 521@2x.png" alt="" />
          <div class="bg-title">NFT Launch</div>
          <div class="bg-text">
            Start your NFT project with our professional advice. From<br />brainstorming
            business ideas, and smart contract<br />development to minting NFT.
          </div>
        </div>
        <div class="options-bg" style="margin-bottom: 23px;">
          <img class="bg-logo" src="/img/Group 121@2x.png" alt="" />
          <div class="bg-title">Customized NFT</div>
          <div class="bg-text">
            Provide a unique product experience to your guests with<br>customize profile picture NFT. 
          </div>
        </div>
        <div class="options-bg" style="margin-right: 20px;">
          <img class="bg-logo" src="/img/Group 101@2x.png" alt="" />
          <div class="bg-title">Entry Tickets NFT</div>
          <div class="bg-text">
            Entry Tickets NFT – Blockchain as a trusted source for both<br>ticket holders and organizers
          </div>
        </div>
        <div class="options-bg">
          <img class="bg-logo" src="/img/Group 131@2x.png" alt="" />
          <div class="bg-title">Membership NFT</div>
          <div class="bg-text">
            Launch NFTs as a digital identity for customers with<br>fastening voting power to NFTs' future organization<br>activities.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.solutions-content {
  .solutions-content-top {
    position: relative;
    width: 13.52rem;
    margin: 0 auto;
    margin-bottom: .23rem;
    .top-bg-img {
      width: 13.25rem;
      height: 5.82rem;
    }
    .top-logo-img {
      position: absolute;
      top: 1.5rem;
      left: 2.79rem;
      width: 574px;
      height: 246px;
    }
    .top-title {
      position: absolute;
      top: 2.39rem;
      left: 4.69rem;
      font-size: .24rem;
      font-weight: 500;
      color: #ffffff;
    }
    .top-text {
      position: absolute;
      top: 3.1rem;
      left: 3.65rem;
      font-size: .12rem;
      font-weight: 500;
      color: #ffffff;
      line-height: .2rem;
    }
  }
  .solutions-content-bottom {
    position: relative;
    .bottom-bg-img {
      width: 100%;
      height: 7.61rem;
    }
    .bottom-options {
      width: 9.11rem;
      color: #ffff;
      position: absolute;
      top: 1.8rem;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      flex-wrap: wrap;
      .options-bg:hover {
        background: url('/img/Rectangle@221x.png');
        background-size: 100%;
      }
      .options-bg {
        position: relative;
        width: 4.38rem;
        height: 2.08rem;
        background: url('/img/Rectangle@2x (1).png');
        background-size: 100%;
        .bg-logo {
          width: .54rem;
          height: .52rem;
          position: absolute;
          top: .31rem;
          left: .39rem;
        }
        .bg-title {
          position: absolute;
          top: .47rem;
          left: 1.11rem;
          font-size: .2rem;
          font-weight: 500;
          color: #ffffff;
        }
        .bg-text {
          position: absolute;
          top: .99rem;
          left: .37rem;
          font-size: .12rem;
          color: #ffffff;
          font-weight: 400;
          line-height: .25rem;
        }
      }
    }
  }
}
</style>
