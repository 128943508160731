<template>
    <div id="app">
      <!-- 头部导航 -->
      <Headerbox></Headerbox>
      <!-- 主体区 -->
        <Content></Content>
      <!-- 页脚 -->
      <Footer></Footer>
    </div>
</template>
<script>
import Headerbox from '../components/Header-box/header-box.vue'
import Content from '../components/Ntf-box/contentbox.vue'
import Footer from '../components/Footer-box/footer.vue'
export default {
  name: 'Nft',
  components: {
    Headerbox,
    Content,
    Footer,
  }
}
</script>
