<template>
  <div id="app">
    <!-- 头部导航 -->
    <Headerbox></Headerbox>
    <!-- 主体区 -->
      <Cryptosbox></Cryptosbox>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Headerbox from '../components/Header-box/header-box.vue'
import Footer from '../components/Footer-box/footer.vue'
import Cryptosbox from '../components/Cryptos-box/cryptosbox.vue'
export default {
  naem: 'Cryptos',
  components: {
    Headerbox,
    Cryptosbox,
    Footer,
  }
}
</script>
