<template>
  <div class="home-content head-height">
    <!-- <div class="bx"> -->
      <div class="slideshow-bgc">
      <div class="slideshow">
        <div class="slideshow-right-text">
          <div
            class="row-text"
            :style="swiperRealIndex != item.index ? 'opacity: 0.36;' : ''"
            v-for="(item, index) in swiperText"
            :key="index"
          >
            <div class="text">{{ item.text }}</div>
            <div class="dian"></div>
          </div>
        </div>
        <div class="slideshow-bg">
          <img class="slideshow-bg-img" src="/img/Group 143@22x.png" alt="" />
          <div class="swiper-box">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="(item, index) in swiperList" :key="index">
                <div class="swiperContent">
                  <img
                    class="slideshow-bg-black"
                    src="/img/Rectangle2@2x.png"
                    alt=""
                  />
                  <div v-html="item.swiperContent"></div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
      </div>
      <div class="ATM-Locations">
        <div>
          <!-- <img
          class="ATM-Locations-logo"
          src="/img/Group 25 Copy 2@2x.png"
          alt=""
        /> -->
        </div>
        <img class="ATM-Locations-bg" src="/img/Group 14@2x.png" alt="" />
        <!-- <div class="ATM-Locations-swiper">
          <swiper v-if="ATMLocationsList.length > 1" :options="swiperOption2" ref="mySwiper2">
            <swiper-slide
              v-for="(item, index) in ATMLocationsList"
              :key="index"
            >
              <div class="swiperContent">
                <div
                  class="swiperContent-list"
                  v-for="(value, index) in item.List"
                  :key="index"
                >
                  <div class="swiperContent-title">{{ value.title }}</div>
                  <div class="swiperContent-text1">{{ value.text1 }}</div>
                  <div class="swiperContent-text2">{{ value.text2 }}</div>
                  <div class="swiperContent-text3">{{ value.text3 }}</div>
                  <div class="swiperContent-text4">{{ value.text4 }}</div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div> -->
      </div>
      <div class="About-us">
        <img class="About-us-bg" src="/img/Group 9@2x.png" alt="" />
        <img class="About-us-logo" src="/img/Rectangle@2x.png" alt="" />
        <div class="About-us-title">About Us</div>
        <div class="About-us-text">
          Hunter’s management team is experienced in digital assets investment,
          trading,<br />operating and finance technology.<br />
          Our vision is to make Web3 ecosystem accessible to every individual
          and business.
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      
      swiperText: [
        {
          index: 0,
          text: 'NFT ATM',
        },
        {
          index: 1,
          text: 'NFT Market',
        },
        {
          index: 2,
          text: 'Hunter Wallet',
        },
        {
          index: 3,
          text: 'Advertising Platform',
        },
        {
          index: 4,
          text: 'Blockchain and NFT Solutions',
        },
      ],
      swiperList: [
        {
          swiperContent: `<div>
          <img style="position: absolute;top: .57rem;left: .68rem;width: 4.54rem;height: 3.14rem;z-index: 1;" src="/img/Group 3@2x.png" alt="" />
          <img style="position: absolute;top: 1.29rem;left: 5.20rem;width: .50rem;height: .85rem;z-index: 1;" src="/img/Group 18 Copy 4@2x.png" alt="" />
          <p class="text1">Support Multi-Cryptocurrencies & NFT Transactions</p>
          <p class="text2">First NFT ATM in Asia</p>
          <p class="text3">Accepts Cash and FPS<br>Operates 24 hours, 30 seconds to complete the transaction</p>
          </div>`,
        },
        {
          swiperContent: `<div>
          <img style="position: absolute;top: .57rem;left: 1.05rem;width: 3.86rem;height: 3.24rem;z-index: 1;" src="/img/Group 2 Copy@2x.png" alt="" />
          <img style="position: absolute;top: 2.61rem;left: 5.20rem;width: 1.48rem;height: .44rem;z-index: 1;" src="/img/Group 3 Copy@2x.png" alt="" />
          <p class="text2" style="top: 1.14rem; left: 5.20rem;">NFT Market</p>
          <p style="top: 1.64rem;line-height: .17rem;" class="text3">Connect professional NFT Projects and Buyers<br>Trade Safely<br><br>BLOCKCHAINS WE SUPPORT<br>Polygon | Ethereum | Solana</p>
          </div>`,
        },
        {
          swiperContent: `<div>
          <img style="position: absolute;top: .46rem;left: 1.25rem;width: 3.44rem;height: 3.49rem;z-index: 1;" src="/img/Group 6@2x.png" alt="" />
          <img style="position: absolute;top: .99rem;left: 5.05rem;width: 1.10rem;height: .76rem;z-index: 1;" src="/img/Group 99@2x.png" alt="" />
          <p class="text2" style="top: 1.80rem; left: 5.20rem;">Hunter Wallet</p>
          <p style="top: 2.30rem;line-height: .17rem;" class="text3">Integrated wallet manages your digital assets<br>NFT Gallery Preview</p>
          </div>`,
        },
        {
          swiperContent: `<div>
          <img style="position: absolute;top: .22rem;left: 1.24rem;width: 4.99rem;height: 4.21rem;z-index: 1;" src="/img/Group 4@2x.png" alt="" />
          <img style="position: absolute;top: .95rem;left: 5.20rem;width: .49rem;height: .41rem;z-index: 1;" src="/img/Group@2xx2.png" alt="" />
          <p class="text2" style="top: 1.43rem; left: 5.20rem;">Hunter O2O<br>Advertising Platform</p>
          <p style="width: 4.00rem;top: 2.30rem;line-height: .17rem;" class="text3">Hunter Online to offline (O2O) Advertising Platform allows brands to connect with<br>and reach their consumers and activate an offline retail experience.<br><br>Any brand targeting Web3 users can advertise using Hunter O2O platform.</p>
          </div>`,
        },
        {
          swiperContent: `<div>
          <img style="position: absolute;top: .47rem;left: 1.27rem;width: 2.97rem;height: 3.59rem;z-index: 1;" src="/img/Group 22@2x.png" alt="" />
          <img style="position: absolute;top: .98rem;left: 5.20rem;width: .65rem;height: .65rem;z-index: 1;" src="/img/Group 7@2x2x.png" alt="" />
          <p class="text2" style="top: 1.80rem; left: 5.20rem;">Blockchain and NFT Solutions</p>
          <p style="font-size: .12rem;width: 4.50rem;top: 2.2rem;line-height: .17rem;" class="text3">Customized blockchain and NFT solutions for clients to solve operational dilemmas.<br>
            Our clients:<br>
            Yiming Sports, MPG Network, Digital Art Fair Asia,<br>
            Art of Nature Contemporary Gallery, <br>
            Hong Kong International Automobile Expo,<br>
            Youth Committee of the Chinese Manufacturers' Association of Hong Kong</p>
          </div>`,
        },
      ],
      swiperRealIndex: 0,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          // reverseDirection: false,
        },
          // observer:true,
          // observeParents:true,
        on: {
          // 2. 在滚动事件中通过上面保存的swiper元素获取当前页索引,可以打印一下c_swiper看一下内部属性
          slideChange: () => {
            let index = this.$refs.mySwiper.swiper.realIndex
            this.swiperRealIndex = index
            // if(index == 0) return this.swiperOption.autoplay.reverseDirection = false
            // if(index == 4) return this.swiperOption.autoplay.reverseDirection = true
          },
        },
        speed: 800,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOption2: {
        loop: true,
        autoplay: {
          delay: 3500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          // reverseDirection: false,
        },
          // observer:true,
          // observeParents:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        speed: 800,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<style lang="less" scoped>
.home-content {
  // .bx {
  //   width: 14.45rem;
  //   margin: 0 auto;
    
    .About-us {
      margin-left: 4.62rem;
      position: relative;
      height: 6.05rem;
      margin-bottom: .63rem;
      .About-us-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 6.74rem;
        height: 6.05rem;
      }
      .About-us-logo {
        position: absolute;
        top: 1.11rem;
        left: 4.04rem;
        width: 2.45rem;
        height: 4.26rem;
      }
      .About-us-title {
        position: absolute;
        top: 2.67rem;
        left: 4.67rem;
        font-size: .24rem;
        font-weight: 500;
        color: #ffffff;
      }
      .About-us-text {
        position: absolute;
        top: 3.25rem;
        left: 4.67rem;
        font-size: .12rem;
        font-weight: 500;
        color: #ffffff;
        line-height: .17rem;
      }
    }
    .slideshow-bgc {
      width: 100%;
      background-color: #151515;
      height: 7.57rem;
      box-sizing: border-box;
    .slideshow {
      position: relative;
      height: 6.48rem;
      box-sizing: border-box;
      padding-left: 4.03rem;
      padding-top: 1.73rem;
      padding-right: 3.67rem;
      // margin-bottom: .61rem;
      .slideshow-right-text {
        position: absolute;
        top: 1.01rem;
        right: 3.70rem;
        z-index: 3;
        .row-text {
          display: flex;
          align-items: center;
          margin-bottom: .07rem;
          justify-content: flex-end;
          font-size: .11rem;
          font-weight: 400;
          color: #ffffff;
          .text {
            margin-right: .07rem;
          }
          .dian {
            width: .08rem;
            height: .08rem;
            background: #fff;
            opacity: 0.36;
            border-radius: 50%;
          }
        }
      }
      .slideshow-bg {
        position: relative;
        height: 4.36rem;
        .slideshow-bg-img {
          position: absolute;
          top: 0;
          left: 1.52rem;
          width: 8.96rem;
          height: 4.4rem;
          z-index: 1;
        }
        .slideshow-yellow {
          position: absolute;
          top: 0;
          left: 1.57rem;
          width: 4.75rem;
          height: 4.36rem;
        }
        .swiper-box {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          .swiper-container {
            height: 100%;
            /deep/ .swiperContent {
              height: 100%;
              font-size: .12rem;
              .slideshow-bg-black {
                position: absolute;
                top: .62rem;
                left: 4.29rem;
                width: 6.23rem;
                height: 3.16rem;
              }
              .text1 {
                position: absolute;
                top: 1.58rem;
                left: 5.8rem;
                z-index: 1;
                color: #fff;
              }
              .text2 {
                position: absolute;
                top: 1.8rem;
                left: 5.8rem;
                z-index: 1;
                color: #fff;
                font-size: .26rem;
              }
              .text3 {
                position: absolute;
                top: 2.33rem;
                left: 5.2rem;
                z-index: 1;
                color: #fff;
              }
            }
          }
          .swiper-button-next {
            width: .68rem;
            height: .68rem;
            background: url('/img/Group 7@2x.png');
            background-size: 100%;
            right: 0;
          }
          .swiper-button-prev {
            width: .68rem;
            height: .68rem;
            background: url('/img/Group 7 Copy@2x.png');
            background-size: 100%;
            left: 0;
          }
          /deep/ .swiper-pagination-bullet-active {
            background: #fff !important;
          }
          /deep/
            .swiper-container-horizontal
            > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
            display: flex;
            flex-direction: column;
            top: .56rem;
            li {
              margin-bottom: .15rem;
            }
          }
          /deep/
            .swiper-container-horizontal
            > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
            left: 11.92rem !important;
          }
        }
      }
    }
}
    .ATM-Locations {
      position: relative;
      padding-top: .71rem;
      height: 6.65rem;
      // padding-bottom: .39rem;
      box-sizing: border-box;
      margin-bottom: .5rem;
      width: 100%;
      .ATM-Locations-logo {
        width: .99rem;
        height: .44rem;
        margin-left: 5.89rem;
        margin-bottom: .25rem;
      }
      .ATM-Locations-bg {
        height: 3.9rem;
        width: 14.45rem;
        margin-left: 2.37rem;
      }
      .ATM-Locations-swiper {
        position: absolute;
        top: 4.12rem;
        height: 2.50rem;
        width: 100%;
        background: rgba(0, 0, 0, 0.84);
        box-shadow: 0 0 .14rem 0 #000000;
        padding-left: 3.85rem;
        padding-right: 3.85rem;
        box-sizing: border-box;
        .swiper-container {
          height: 100%;
          .swiperContent {
            height: 100%;
            display: flex;
            padding-left: 1.93rem;
            .swiperContent-list {
              padding-top: .53rem;
              color: #dedede;
              font-weight: normal;
              font-size: .12rem;
              margin-right: .33rem;
              width: 2.592rem;
              .swiperContent-title {
                font-size: .22rem !important;
                margin-bottom: .12rem;
              }
              .swiperContent-text1 {
                margin-bottom: .04rem;
              }
              .swiperContent-text2 {
                width: 2.592rem;
                margin-bottom: .11rem;
              }
              .swiperContent-text3 {
                margin-bottom: .07rem;
              }
            }
          }
        }
        .swiper-button-next {
          width: .68rem;
          height: .68rem;
          background: url('/img/Group 7@2x.png');
          background-size: 100%;
          right: 0;
          top: .95rem;
        }
        .swiper-button-prev {
          width: .68rem;
          height: .68rem;
          background: url('/img/Group 7 Copy@2x.png');
          background-size: 100%;
          left: 0;
          top: .95rem;
        }
        /deep/ .swiper-pagination-bullet {
            background: #fff !important;
          }
          /deep/ .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: 0;
          }
      }
    }
  // }
}
</style>
