import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 导入less文件
import Index from '../src/assets/css/index.less'
// import homebox from './components/Home-Box/homebox.less'
import Header from './components/Header-box/header.less'
import Footer from './components/Footer-box/footer.less'
// import Content from './components/Ntf-box/contentbox.less'
// import Cryptos from './components/Cryptos-box/cryptosbox.less'
// import Nftinquirebox from './components/Nftinquire-box/nftinquirebox.less'
// import participatebox from './components/Participate-box/participatebox.less'
import nftmarketbox from './components/NftMarket-box/nftmarketbox.less'
import loginbox from './components/login/loginbox.less'
import marketList from './components/marketList/marketList.less'
import marketDetails from './components/MarketDetails/MarketDetails.less'
import marketPayList from './components/marketPayList/marketPayList.less'
import marketPay from './components/marketPay/marketPay.less'
import api from './api/index.js'
import store from './store/index.js'
import './router/permission.js'
import formatTime from './common/fileters'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementUI from 'element-ui'; // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import i18n from '@/lang/index'
// 导入animate动画库
import 'animate.css'
// 导入js文件
import Flexible from '../node_modules/amfe-flexible/index.min.js'
import Components from '@/common/components.js'
Vue.prototype.$api = api
Vue.prototype.formatTime = formatTime

// 腾讯滑块验证(新建一个js文件)
// import TencentCaptcha from '@/assets/js/TencentCaptcha'
// 使用
// Vue.prototype.$bus = new Vue();

import VueAwesomeSwiper from 'vue-awesome-swiper'
// zhLocale.el.pagination = {
//   goto: '前往',
//   pagesize: '條/頁',
//   total: `共 {total} 條`,
//   pageClassifier: '頁',
// };
// import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper);

// 注册
// Vue.use(TencentCaptcha);
Vue.use(ElementUI, {
  locale: zhLocale,
});
Vue.use(Index)
Vue.use(Flexible)
// Vue.use(homebox)
Vue.use(Header)
Vue.use(Footer)
// Vue.use(Content)
// Vue.use(Cryptos)
// Vue.use(Nftinquirebox)
// Vue.use(participatebox)
Vue.use(nftmarketbox)
Vue.use(loginbox)
Vue.use(marketList)
Vue.use(marketDetails)
Vue.use(marketPayList)
Vue.use(marketPay)
Vue.use(Components)



Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
  // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
		
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    setRem();
    window.addEventListener('resize', setRem);//浏览器窗口大小改变时调用rem换算方法
   }
   else{
    setRemPC()
      window.addEventListener('resize', setRemPC);//浏览器窗口大小改变时调用rem换算方法
   }
  }
}).$mount('#app')

// 手机端
function setRem() {
  var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}
//pc端
function setRemPC() {
  var width = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  if(bodyWidth >= 1280 && bodyWidth <= 1920) { // rem适配 屏幕缩放太大或太小会错乱 加个判断在多少尺寸之内才会变化
    var rem = bodyWidth * width;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  } else {
    document.getElementsByTagName('html')[0].style.fontSize = 100 + 'px'; // 如果不在判断尺寸之内就设置为基础值, 这个基础值100对应第一个100
  }
}
