import axios from 'axios'
import { Loading, MessageBox, Message } from 'element-ui'
import store from '@/store/index'
import router from '@/router/index'

// 定义 loading
let loading
// let url = '/website'
let url = ''
// if(window.location.hostname =="localhost"){
// var url='https://test.api.hk-wallet.com'
// }else{
// var url=''
// }
// loading开始 方法
function startLoading() {
  // element-ui loading 服务调用方式
  loading = Loading.service({
    lock: true,
    text:
      store.state.language === 'zh-CN'
        ? '加载中'
        : store.state.language === 'tw'
        ? '加載中'
        : 'loading',
    spinner: 'el-icon-loading', // 自定义图标
    background: 'rgba(0, 0, 0, 0.3)',
    // fullscreen: false
  })
}

// loading结束 方法
function endLoading() {
  loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}
const service = axios.create({
  baseURL: url, // url = base url + request url
  timeout: 60000, // request timeout
})

// request interceptor
const whiteUrl = [
  '/website/v1/verifyTencentImageCode',
  '/website/v1/getNftCommodityList',
  '/website/v1/sms/getCode',
  '/website/v1/sms/login',
]
service.interceptors.request.use(
  (config) => {
    // 请求头加上lang字段参数为本地存储的语言  后端根据参数返回语言
    config.headers['Authorization'] =
    store.state.language === 'en'
        ? 'en-US'
        : store.state.language === 'tw'
        ? 'zh-HK'
        : 'zh-CN'
    if (store.state.userInfo.token && !whiteUrl.includes(config.url)) {
      // let each request carry token让每个请求携带令牌
      // ['X-Token'] is a custom headers key 是一个自定义标题键
      // please modify it according to the actual situation请根据实际情况修改
      config.headers['token'] = store.state.userInfo.token
      // config.headers['token'] = 'store.state.userInfo.token'
    }
    if(config.url != '/website/v1/getSysLikeList') {
      showFullScreenLoading()
    }
    return config
  },
  (error) => {
    // console.log(error) // for debug
    tryHideFullScreenLoading()
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status如果您想获取http信息，如标头或状态
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code通过自定义代码确定请求状态
   * Here is just an example这里只是一个例子
   * You can also judge the status by HTTP Status Code您还可以通过HTTP状态码来判断状态
   */
  (response) => {
    tryHideFullScreenLoading()
    const res = response.data
    // console.log(res);
    // 如果自定义代码不是20000，则判断为错误.
    if (res.code !== '0000' && res.code !== 200) {
      if(res.code !== '1200') {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000,
        })
      }

      // 50008: 非法token; 50012: 其他客户端已登录; 50014: Token 已过期;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm(
          store.state.language === 'zh-CN'
        ? '您已注销，可以取消以停留在此页面，或重新登录'
        : store.state.language === 'tw'
        ? '您已註銷，可以取消以停留在此頁面，或重新登錄'
        : 'You have successfully logged out.It‘s a good idea to close your browser windows',
        store.state.language === 'zh-CN'
        ? '确认注销'
        : store.state.language === 'tw'
        ? '確認註銷'
        : 'Confirm Logout',
          {
            confirmButtonText: 'Re-Login',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        ).then(() => {})
      }
      if (res.code ===  '1200') {
        if(router.currentRoute.path !== '/nftmarket') {
          router.push('/nftmarket')
        }
        store.commit('remTokenInfo')
        store.commit('setLoginShow', true)
      }
      if(res.code == 1916) {
        router.push('/nftmarket')
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    tryHideFullScreenLoading()
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service
