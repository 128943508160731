<template>
<div id="app">
  <!-- 缓存的页面，缓存$route.meta.keepAlive为true的组件 -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <!-- 不缓存的页面，不缓存$route.meta.keepAlive为false的组件 -->
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <contact-us v-show="pathUrl"></contact-us>
</div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  computed: {
      pathUrl(){
        return this.$route.path == '/home' || this.$route.path == '/nft' || this.$route.path == '/cryptos' || this.$route.path == '/participate'
      }
    },
  mounted() {
    this.isPhone()
  },
  methods: {
    isPhone() {
      if (
        /(android|iphone|ipad|PlayBook|BB10)/i.test(window.navigator.userAgent)
      ) {
        window.location.href = 'https://m.hunter-wallet.com'// h5线上地址
        // window.location.href = 'tm.hunter-wallet.com'
      }
    },
  },
}
</script>
