import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
// 导入组件库
import Home from '../views/Home.vue'
import Nft from '../views/Nft.vue'
import Cryptos from '../views/Cryptos.vue'
import Participate from '../views/Participate.vue'
import Nftinquire from '../views/Nftinquire.vue'
import NftMarket from '../views/NftMarket.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/nft',
    component: Nft
  },
  {
    path: '/cryptos',
    component: Cryptos
  },
  {
    path: '/participate',
    component: Participate
  },
  {
    path: '/nftinquire',
    component: Nftinquire
  },
  {
    path: '/nftmarket',
    component: NftMarket,
    children: [
      {
        path: '',
        component: () => import('@/components/marketList/marketList.vue'),
        meta: {
          keepAlive: true
        },
      },
      {
        path: 'marketDetails',
        component: () => import('@/components/MarketDetails/MarketDetails.vue'),
      },
      {
        path: 'marketPayList',
        component: () => import('@/components/marketPayList/marketPayList.vue'),
      },
      {
        path: 'marketPay',
        component: () => import('@/components/marketPay/marketPay.vue'),
      }
    ]
  },
  
]

const router = new VueRouter({
  routes,
  mode: 'history'
})
export default router
