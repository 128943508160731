<template>
  <div id="app">
    <!-- 头部导航 -->
    <Headerbox></Headerbox>
    <!-- 主体区 -->
      <Participatebox></Participatebox>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Headerbox from '../components/Header-box/header-box.vue'
import Footer from '../components/Footer-box/footer.vue'
import Participatebox from '../components/Participate-box/participatebox.vue'
export default {
  naem: 'Participate',
  components: {
    Headerbox,
    Footer,
    Participatebox,
  }
}
</script>
