<template>
  <div id="app">
    <!-- 首页导航 -->
    <div class="header-box">
        <headerBox></headerBox>
    </div>
     <!-- 首页背景加主体 -->
     <div class="home-box">
        <homeBOX></homeBOX>
     </div>
     <!-- 页脚 -->
     <div class="footer-box">
        <footerBox></footerBox>
     </div>
  </div>
</template>

<script>
import headerBox from '../components/Header-box/header-box.vue'
import homeBOX from '../components/Home-Box/homebox.vue'
import footerBox from '../components/Footer-box/footer.vue'
export default {
  name: 'Home',
  data() {
      return {
   
      }
    },
    created() {
      this.isPhone()
      
    },
    mounted() {
    },
    methods: {
          isPhone() {
              if((/(android|iphone|ipad|PlayBook|BB10)/i).test(window.navigator.userAgent)){
            window.location.href = 'https://m.hunter-wallet.com/#/';
        }

    }
  },
  components: {
    headerBox,
    homeBOX,
    footerBox,
  }
}
</script>
