<template>
  <div id="app">
    <!-- 头部导航 -->
    <Headerbox></Headerbox>
    <!-- 主体区 -->
      <nft-market-box></nft-market-box>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Headerbox from '../components/Header-box/header-box.vue'
import Footer from '../components/Footer-box/footer.vue'
import NftMarketBox from '../components/NftMarket-box/nftmarketbox.vue'
export default {
  naem: 'Participate',
  components: {
    Headerbox,
    Footer,
    NftMarketBox,
  }
}
</script>
