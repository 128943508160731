<template>
  <div id="app">
    <!-- 头部导航 -->
    <Headerbox></Headerbox>
    <!-- 主体区 -->
      <!-- <Nftinquirebox></Nftinquirebox> -->
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Headerbox from '../components/Header-box/header-box.vue'
import Footer from '../components/Footer-box/footer.vue'
// import Nftinquirebox from '../components/Nftinquire-box/nftinquirebox.vue'
export default {
  naem: 'Nftinquire',
  components: {
    Headerbox,
    Footer,
    // Nftinquirebox,
  }
}
</script>
