<template>
  <div class="footer">
    <div class="footer_top">
      <div class="footer_top_item">
      <!-- <p>
        <img src="/img/Shape-tel.png" alt="">
        <span>852-3069-7821</span>
      </p>
      <p>
        <img src="/img/Shape-fg.png" alt="">
        <span>Hunterkinggroup</span>
      </p>
      <p>
        <img src="/img/Group.png" alt="">
        <span>Hunterkinggroup</span>
      </p> -->
      <p>
         <img style="height: .15rem; width: .15rem;" src="/img/Shape-kf.png" alt="">
        <span>852-3069-7821</span>
      </p>
      <p style="margin-left: .25rem;">
         <img style="height: .12rem; width: .15rem;" src="/img/mail.png" alt="">
        <span>service@hunter-group.io</span>
      </p>
      </div>
    </div>
    <div class="footer_bootom">
      © Copyright 2022 Hunter Group - All Rights Reserved
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
