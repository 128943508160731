<template>
  <div class="market_content">
    <div class="content_box">
    <router-view>
    </router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nftmarketbox',
  data() {
    return {
    }
  },
}
</script>

<style></style>
